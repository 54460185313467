.create-users-form-notes {
  font-size: 1rem;
  font-family: 'Merriweather','Helvetica Neue',Arial,sans-serif;
  /* font-style: italic; */
  font-weight: 200;
  margin-left: 4.5rem;
  padding-bottom: 3rem;
  line-height: 1.5rem;
}

.create-users-form h2 {
  margin-top: 1rem;
}

.input-font-size {
  font-size: 1.1rem;
  font-weight: bold;
}

.create-users-form-item-container {
  width: 100%;
  background-color: #f7f6f0;
}

.create-class-submission-button {
  display: flex;
  justify-content: flex-end;
}