@import url('https://fonts.googleapis.com/css?family=Ramabhadra&display=swap');

:root {
  --mainText: #222c34;
}

.App {

}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  background: url('./assets/main.png') no-repeat;
  background-size: cover;
  min-height: 30vh;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: calc(10vh);
  font-family: "Merriweather", "Ramabhadra", "Helevetica", "Arial", sans-serif;
  color: hsla(0,0%,100%,0.92);
  text-shadow: 4px 4px 6px #061F3b;
  letter-spacing: 0px;
}

.App-link {
  color: #61dafb;
}

.content-section {
  display: flex;
  margin-left: calc(20vw / 2);
  margin-right: calc(20vw / 2);
  padding-top: 1rem;
  margin-bottom: 5rem;
}

.navigation-container {
  width: 20vw;
  height: 100%;
  padding-top: 1rem;
  position: sticky;
  top: 0;
}

.navigation-menu-item {
  list-style: none;
  text-align: left;
  font-family: 'Ramabhadra','Merriweather','Helvetica Neue',Arial,sans-serif;
  line-height: 1.5;
}

.navigation-menu-level-2 {
  margin-left: 1rem;
}

.navigation-menu-level-3 {
  margin-left: 2rem;
  font-weight: 400;
  font-family: 'Helvetica';
  list-style: circle;
}

.navigation-menu-item a, .navigation-menu-item a:visited  {
  text-decoration: none;
  color: #065bb6bb;
}

.content-container {
  width: 100%;
  margin-left: 1rem;
  text-align: left;
  padding-bottom: 2rem;
}


.content-footer {


}


.copyright {
  text-align: right;
  margin-top: 1rem;
  padding-top: 2rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  font-family: 'Ramabhadra', 'Merriweather', 'Helvetica Neue', Arial, sans-serif;
}

h1 {
  font-family: 'Merriweather','Helvetica Neue',Arial,sans-serif;
  font-size: 2rem;
  font-weight: bold;
  color: grey;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* pageTitle are basically functioning like h1  */
.pageTitle {
  font-family: 'Merriweather','Helvetica Neue',Arial,sans-serif;
  font-size: 2rem;
  font-weight: bold;
  color: grey;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
/* TODO: for semantic reasons, convert pageTitle to actual H1 */

h2 {
  font-family: 'Merriweather','Helvetica Neue',Arial,sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: grey;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

/* sectionHeading1 loosely matches function of h2 */
.sectionHeading1 {
  font-family: 'Merriweather','Helvetica Neue',Arial,sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: grey;
  margin-top: 2rem;
  margin-bottom: 1rem;
}


.sectionHeading2 {
  font-family: 'Merriweather','Helvetica Neue',Arial,sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  color: grey;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.sectionHeading3 {
  font-family: 'Merriweather','Helvetica Neue',Arial,sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  color: grey;
  margin-top: 2rem;
  margin-bottom: 1rem;
}


.normalText {
  font-family: 'Merriweather','Helvetica Neue',Arial,sans-serif;
  font-size: 16px;
  color: var(--mainText);
  margin-bottom: 1rem;
  line-height: 1.5;
  text-justify: auto;
  text-indent: 2rem;
}

.normalText .no-indent {
  text-indent: 0rem;
}

table, tr, td {
  border: 1px solid black;
  padding: 1rem;
  background-color: #f7f6f0;
}

table .normalText {
  text-indent: 0;
}

table .sectionHeading3 {
  color: var(--mainText);
  font-size: 16px;
  font-weight: normal;
  /* text-align: center; */
}

b {
  font-weight: bold;
}

i {
  font-style: italic;
}

a {
  color: #065bb6bb;
}

.prototype-diagrams {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login-input {
  border: none;
  border-bottom: 1px solid gray;
  margin-bottom: 1rem;
  width: 100%;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.login-form {
  width: 40%;
  margin-top: 2rem;
}

.login-button {
  margin-top: 1rem;
  padding: .5rem;
  height: 3rem;
  font-size: 1.25rem;
  border-radius: .5rem;
  background-color: #c2d8f8d8;
  width: 100%;
}

ol {
  list-style: decimal inside;
}

/* div {
  border: 1px solid black !important
} */

@media screen and (max-width: 796px) {
  .content-section {
    margin-left: 5vw;
    margin-right: 5vw;
  }
}