.navigation-admin-menu {
  margin-top: 1rem;
  color: gray;
  font-family: "Ramabhadra", "Helevetica", "Arial", sans-serif;
  font-weight: 400;
  background-color: #f7f6f0;
  padding: 1rem;
  border: 1px solid #a0a09f;
  border-radius: 5px;
  width: 60%;
}

.navigation-logout-button {
  margin-top: 1rem;
}
