.class-list-page-container {
  margin-left: 1.5rem;
}

.class-list-buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 25%;
}

.class-list-general-button {
  margin-top: 1rem;
  padding: 0rem;
  height: 3rem;
  font-size: 1.25rem;
  border-radius: .5rem;
  font-family: "Ramabhadra", "Helevetica", "Arial", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.class-list-button-active {
  font-weight: bold;
}

.class-list-button {
  background-color: #f7f6f0
}

.create-new-class-button {
  background-color: #c2d8f8d8;
  text-decoration: none;
  border: 1px solid #065bb679;
}

.class-list-students-container {
  margin-top: 1rem;
  width: 75%;
  margin-left: 2rem;
  background-color: #f7f6f0;
  padding: 1rem;
  border: 1px solid #a0a09f;
}

.class-list-students-container h3 {
  margin: 0;
  display: flex;
  justify-content: center;
}

.class-list-page-main-container {
  display: flex;
  flex-direction: row;
}
