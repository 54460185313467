.nvq-instrument-table {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.nvt-question-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.labels-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1.2rem;
}

.label {
  width: calc(100%/9);
  display: flex;
  justify-content: center;
  padding: 1rem 0rem;
}

.nvt-radio-input-container {
  background-color: #f7f6f0;
  border: 1px solid black;
  width: calc(100% / 9);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
}

.nvt-radio-inputs-container {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.nvt-submission-buttons-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.nvt-submission-button {
  height: 3rem;
  font-size: 1.25rem;
  border-radius: .5rem;
  background-color: #c2d8f8d8
}