.nvt-results {
  border: 1px solid black;
}

.title {
  font-size: 22px;
  font-weight: bold;
  font-family: 'Merriweather';
}

.cartesian-line {
  stroke: #dedede;
}

.emphasized-cartesian-line {
  stroke: #dedede;
  stroke-width: 8px;
  stroke-linecap: square;
  stroke-linejoin: miter;
}

.label {
  font-size: 16px;
  font-family: 'Merriweather', sans-serif;
  color: rgb(60, 59, 73);
}

.result-label {
  font-size: 16px;
  font-weight: bold;
  font-family: 'Merriweather', sans-serif;
  color: rgb(33, 32, 55);
}

.text-anchor-start {
  text-anchor: start;
}

.text-anchor-middle {
  text-anchor: middle;
}

.text-anchor-end {
  text-anchor: end;
}

.circle {
  fill: rgba(202, 204, 208, .4);
  stroke: rgb(170, 170, 170);
}

.survey-result-circle {
  fill: rgba(30, 77, 230, 0.6);
  stroke: rgb(30, 77, 230);
}

.axis-title {
  font-weight: bold;
  font-family: 'Merriweather', serif;
  font-size: 16px;
}

.graph-selected-prototype {
  fill: rgba(230, 77, 30, 0.6);
  stroke: rgb(230, 30, 30);
}

.graph-line {
  stroke: #000;
  marker-end: url(#line-arrow);
  stroke-width: .5%;
}

.line-marker {
  fill: #000;
  stroke: #000;
}

.graph-legend {
  font-family: "Merriweather";
}

.bold {
  font-weight: bold;
}
