.matrix-cards {
    border: 1.5px solid gray;
    height: 10rem;
    width: 11rem; 
    outline: none;
    position: relative;
    color: var(--mainText);
    box-sizing: border-box;
}

.matrix-cards:hover {
    cursor: pointer;
    filter: brightness(85%);
}

.matrix-cards:active {
    background-color: yellow;
}

.matrix-cards:focus {
    border: 1.5px solid black;
}

.matrix-container {
    position: relative;
    height: 100%;
}

.matrix-card-title {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1rem;
    font-family: Ramabhadra;
    font-weight: bold;
}
.matrix-card-percentage {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1rem;
    font-family: Ramabhadra;
}
.matrix-card-tagline {
    position: absolute;
    width: 100%;
    height: auto;
    top: 3.5rem;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    font-size: 1.25rem;
    font-weight: bold;
    font-family: Merriweather, Ramabhadra;
    line-height: 1.35rem;
}
.matrix-card-description {
    position: absolute;
    height: 2.9rem;
    width: 100%;
    bottom: 0rem;
    font-size: .75rem;
    display: flex;
    justify-content: center;
}

.target-zone {
    background-color: rgba(113, 172, 113, 0.509);
}

.emphasis-card {
    background-color: pink;
}