.template-results-select-section {
  margin-bottom: 2rem;
}

.template-results-buttons-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}

.nvt-submission-selector-button {
  height: 2rem;
  font-size: 1rem;
  border-radius: .5rem;
  /* background-color: #c2d8f8d8; */
  margin: .25rem;
}

.nvt-submission-selected-button {
  background-color: #c2d8f8d8;
  border: 1px solid black;
  outline: none;
}

.correlation-table-container {
  margin-right: 5rem;
  font-family: 'Merriweather','Helvetica Neue', Arial, Helvetica, sans-serif
}

.top-prototype-correlation {
  font-weight: bold;
}