.change-prototype-button {
  padding: 1rem;
  font-size: 1rem;
  margin: .25rem;
  width: 9rem;
  border-radius: 4px;
  border: .1rem solid black;
}
.change-prototype-button:hover {
  background-color: #f7f6f0;
}

.active-prototype-button {
  padding: 1rem;
  background-color: #be946b4b;
  border: .2rem solid black;
}

.change-prototype-buttons-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 30%;
  width: 30%;
  margin-left: 3rem;
}