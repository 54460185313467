path {
    fill: #fff;
    stroke: #dedede;
    stroke-width: 1px;
}

.selected {
    fill: #be946b4b;
    stroke: #000;
}

.user-selected {
    fill: #6b88be4b;
    stroke: #000;
}

.both-selected {
    fill: gray;
    stroke: #000;
}


.bullseye-label {
    fill: #000;
    text-anchor: middle;
    font-family: 'Merriweather';
    font-size: .9rem;
    letter-spacing: .03rem;
}

.label-text-bg {
    fill: #065bb623;
    stroke: #000;
}

.core-competence {
    fill: #065bb623;
}

.plus-sign {
    font-size: 19px;
}