.capitals-section {
    width: 90%;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    margin-left: 3rem;
}

.capitals-container {
    display: flex;
    align-items: baseline;
    padding: .5rem;
}

.capitals-left-word-container {
    width: 25%;
    text-align: left;
}

.captials-vs-container {
    width: 10%;
    text-align: center;
}

.capitals-right-word-container {
    width: 25%;
    text-align: left;
    padding-left: 2.5rem;
}

.capitals-description-container {
    width: 40%;
    text-align: left;
}